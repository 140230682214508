@use "src/styles/pallete";

// Toolbar Style
.data-table > .MuiToolbar-root:first-child {
	background: pallete.$tr_background;

	svg, h6 {
		color: white;
	}

	.MuiInput-input {
		font-weight: 500;
		background: white;
		color: pallete.$black-2;
		padding-left: 10px;
		border-radius: 5px;
	}
}

.data-table {
	justify-content: center !important;
	#pagination-rows {
		color: #2e2e2e;
	}

	.MuiInputBase-input {
		color: white;
	}

	.MuiInput-underline:hover:not(.Mui-disabled)::before {
		border-bottom: 2px solid rgba(white, 0.87);
	}

	.MuiInput-underline:before, .MuiInput-underline::after {
		display: none;
	}

	.MuiButton-label {
		color: white !important;
	}

	th {
		background: pallete.$tr_background;
		box-shadow: inset 0px -1px 0px #3366FF;

		.MUIDataTableHeadCell-sortActive-95 {
			color: white;
		}

		svg {
			color: white !important;
		}

		button {
			margin: 0;
			padding: 0;

			&:hover {
				background-color: pallete.$tr_background !important;
			}
		}

		span, div {
			color: white;
			font-weight: 600;
			line-height: 15px;
			letter-spacing: 0.05rem;
			text-transform: uppercase;
			align-items: center;
			justify-content: center !important;
		}
	}
}

.no-records-found {
	margin: 60px auto 0;
}

.bulk-employers-details-table {
	th:nth-child(2) {
		width: 500px;

		div, span {
			text-align: center;
			color: white;
			font-weight: 600;
			line-height: 15px;
			letter-spacing: 0.05rem;
			text-transform: uppercase;
			justify-content: center;
		}
	}

	th:nth-child(3), th:nth-child(4), th:nth-child(5), th:nth-child(6) {
		width: 300px;

		div, span {
			text-align: center;
			color: white;
			font-weight: 600;
			line-height: 15px;
			letter-spacing: 0.05rem;
			text-transform: uppercase;
			justify-content: center;
		}
	}
}

.optout-table {
	// center NIN header
	th:nth-child(5) {
		width: 150px;

		span {
			justify-content: center;
		}
	}
}

.payments-table {
	th:nth-child(1), th:nth-child(2), th:nth-child(3) {
		div, span {
			justify-content: center;
			text-align: center;
		}
	}
}

.manage-your-companies-table {
	// center Status, Enrolled Employees and Company Number headers
	th:nth-child(1), th:nth-child(2), th:nth-child(3), th:nth-child(4), th:nth-child(5), th:nth-child(6) {
		div, span {
			justify-content: center;
			text-align: center;
		}
	}

	// buttons column width
	th:nth-child(6) {
		width: 120px;
	}

	// status column width
	th:nth-child(4) {
		width: 180px;
	}

	// status column width
	th:nth-child(8) {
		width: 250px;
	}

	// title column width
	th:nth-child(1) {
		width: 220px;
	}

	// status column width
	th:nth-child(3) {
		width: 100px;
	}

}

.funds-table {
	// center Status, Enrolled Employees and Company Number headers
	th:nth-child(1), th:nth-child(2), th:nth-child(3), th:nth-child(4), th:nth-child(5), th:nth-child(6) {
		div, span {
			justify-content: center;
			text-align: center;
		}
	}

	// name column width
	th:nth-child(1) {
		width: 500px;
	}

	// icon column width
	th:nth-child(3) {
		width: 170px;
	}
}

.modal_mui {
	min-width: 900px !important;
}

.individuals-table {
	// center Status, Enrolled Employees and Company Number headers
	th:nth-child(1), th:nth-child(2), th:nth-child(3), th:nth-child(4), th:nth-child(5), th:nth-child(6), th:nth-child(7), th:nth-child(8) {
		div, span {
			justify-content: center;
			text-align: center;
		}
	}

	// icon column width
	th:nth-child(6) {
		width: 150px;
	}

	.MUIDataTableToolbar-actions-193 {
		display: contents;
	}

	.status {
		padding: 1px 1px;
		border-radius: 16px;
	}

	// in-progress
	.status-0 {
		color: pallete.$black_1;
		background: pallete.$black_4;
		//noinspection CssInvalidPropertyValue
		text-wrap: nowrap;
		@extend .status
	}

	// success
	.status-1 {
		color: pallete.$success_status;
		background: pallete.$success_background;
		@extend .status
	}

	// error
	.status-2 {
		color: pallete.$error_status;
		background: pallete.$error_background;
		@extend .status
	}

	// processing
	.status-3 {
		color: pallete.$processing_status;
		background-color: pallete.$processing_background;
		@extend .status
	}
}

.data-table-width {
	width: 100% !important;
}
