@use "src/styles/pallete";

@mixin extra-contributions-default-container {
	& {
		margin-bottom: 200px;
	}
}

@mixin listing-information-holder {
	& {
		text-align: center;
		color: black;
		padding-top: 20px;
		margin-bottom: 120px;
		padding-left: 20px;
		padding-right: 19px;
		width: 100%;
		box-shadow: none;
		min-height: 90vh;
		height: auto !important;

		.title {
			font-size: 21px;
			margin-top: 38px;
		}

		.subtitle {
			font-size: 13px;
		}

		#button-holder {
			width: 100%;

			p {
				font-size: 13px;
			}

			.MuiButton-containedPrimary {
				background: pallete.$information;
				width: 100%;
			}

			.MuiCardHeader-root {
				min-height: 81px;
				padding-top: 0;
				padding-bottom: 0;
			}

			.MuiCardHeader-avatar {
				.MuiAvatar-colorDefault {
					background: transparent;
					color: pallete.$information;

					svg {
						font-size: 38px;
					}
				}
			}

			.MuiCardHeader-action {
				* {
					color: pallete.$information;
				}
			}

			.MuiCardHeader-action {
				margin-top: 20px;
			}
		}

		.default-card-list {
			cursor: pointer;
			width: 100%;
			text-align: left;
			border: none;
			margin-top: 12px;

			background: pallete.$backgroundColor;
			border-radius: 12px;
			box-shadow: none;

			.MuiIconButton-root:hover {
				background-color: transparent;
			}

			.MuiCardHeader-title {
				color: pallete.$dark_blue_5;
				font-size: 15.83px;
				font-weight: 700;
			}

			.MuiCardHeader-subheader {
				color: pallete.$green2;
				font-size: 11.83px;
				font-weight: 600;
			}

			.MuiCardContent-root {
				padding-top: 0px;

				* {
					color: pallete.$information;
					margin-bottom: -5px;
				}
			}

			.trash-icon {
				color: pallete.$information;
				cursor: pointer;
			}

			.MuiCardHeader-root {
				padding-bottom: 10px;
			}

			.money-value {
				margin-left: 12px;
				font-weight: bold;
			}

			.lock-icon {
				* {
					font-weight: normal !important;
				}
			}

			.bank-name {
				font-size: 15.83px;
				font-weight: bold;
				color: pallete.$dark_blue_5;
			}

			.header-title-info-holder {
				font-size: 12px;

				* {
					color: pallete.$information;
				}

				.date-info {
					margin-left: 12px;
					line-height: 2;
				}
			}

			.no-contributions {
				* {
					padding-top: 13px;
					color: pallete.$black_2;
					font-size: 14px;
				}
			}
		}

		.contribution-info-container {
			margin-top: 20px;

			.container-title {
				font-size: 10px;
			}
		}

		.one-off-contribution-date, .next-contribution-date {
			font-size: 12px;

			svg {
				color: pallete.$information;
			}

			.date {
				font-weight: bold;
				color: pallete.$information;
			}

			.next-on-label {
				color: rgba(pallete.$black, 0.4);
				line-height: 2.3;
			}
		}

		.one-off-contribution-date {
			line-height: 2;
		}

		.custom-flex-grid-base {
			width: 4.333333%;
			flex-basis: 4.333333%;

			@media (min-width: 0) and (max-width: 375px) {
				width: 10.333333%;
			}
		}
	}
}


#extra-contributions-default-container {
	@include extra-contributions-default-container;
}

#listing-information-holder {
	@include listing-information-holder;
}
