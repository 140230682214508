.manage-software-title {
	font-weight: 600;
	color: #3C69E4;
}

.manage-software-warning {
	margin: 4rem;
	font-weight: 600;
	color: #888989;
}

.manage-software-table-head {
	 font-weight: bolder;
	 line-height: 0;
	 background: #fefefe;
}