.pop-up-title {
	color: red;
	border-bottom: 1px solid lightgray;
	margin-bottom: 15px;
}

.pop-up-paper {
	position: absolute;
	width: 400px;
	background-color: white;
	border: 2px solid #3C69E4;
	border-radius: 5px;
	padding: 32px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}