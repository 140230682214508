@use "src/styles/pallete";
@use "sass:color";
@use "activeYourAccount" as acya;

#tab-container {
    background: transparent;
    box-shadow: none;
    color: black;
    width: 90%;

    @media (max-width: 767px) {
        width: 100%;
    }

    .MuiTab-textColorInherit.Mui-selected {
        color: pallete.$light_blue;
    }

    @media (min-width: 0px) and (max-width: 767px) {
        width: 100%;
    }
}
.tab-panel {
    width: 100%;

	@include acya.account-card-styles;
	@include acya.inner-account-container-styles;
	@include acya.card-header-button-styles;

    &.vertically-padded { margin-top: 6rem; }
}

.cards-box-container {
    padding: 30px !important;

    .default-button {
        @extend .default-button;

        border-radius: 5px;
        border: 1px solid white;

        &:hover{
            background-color: color.adjust(pallete.$information, $lightness: 0.90%);
        }
    }

    .employee-table-row {
        cursor: pointer;
        &:hover {
            background: rgba(#fefefe, 0.5);
            * {
                font-weight: 600;
            }
        }
    }
}

.upload-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    min-height: 12rem;
    padding: 4rem;

    outline-width: 0;
    border: 1px dashed pallete.$light_blue;

    &:hover {
        transition: linear 0.12s;
        background: color.adjust(pallete.$light_blue, $lightness: 30%);
    }

    svg {
        width: 6rem;
        height: 6rem;
        // color: #4c85de
        color: #4061b7
    }
}

.overflow-table{
    overflow: auto;
}
