@use "src/styles/pallete";

.tab-button {
	background: linear-gradient(269.93deg, #4062B8 0.05%, rgba(64, 98, 184, 0) 99.94%), #1F3762;
	border-radius: 8px;
	width: 330px;
	cursor: pointer;
	padding: 2px;
	transition: 500ms;
	margin-bottom: 30px;

	&:hover {
		background: linear-gradient(269.93deg, #4062B8 0.05%, rgba(64, 98, 184, 0) 99.94%), pallete.$information;
		transition: 500ms;
	}

	.tab-button-title {
		padding-top: 20px;
		padding-bottom: 10px;
		color: white;
		font-weight: 500;
		font-size: 1rem;
		letter-spacing: 0.015em;
	}

	img {
		height: 93px;
	}
}

.tab-button-inactive {
	background: linear-gradient(269.93deg, #8c8c8c 0.05%, rgba(88, 88, 88, 0) 99.94%), #252525;
	opacity: 0.5;
	cursor: not-allowed;
	border-radius: 8px;
	width: 300px;
	padding: 2px;
	margin-bottom: 30px;

	.tab-button-title {
		padding-top: 20px;
		padding-bottom: 10px;
		color: white;
		font-weight: 500;
		font-size: 1rem;
		letter-spacing: 0.015em;
	}

	img {
		height: 93px;
	}

	@media (max-width: 594px) {
		min-width: 200px;
	}
}

