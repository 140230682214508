@use "src/styles/pallete";

#gov-top-up-explained-dialog {
    .MuiDialog-paperFullWidth {
        background-color: pallete.$backgroundColor;
        max-width: 347px;
        .MuiDialogTitle-root {

            img {
                width: 100%;
                max-width: 225px;
                height: 234px;
            }
        }
        .MuiDialogContent-root {
            * {
                color: pallete.$black;
            }
            h1 {
                font-size: 23px;
                font-weight: bold;
            }
        }
        .MuiDialogActions-root {
            button {
                background-color: pallete.$information;
                color: white;
                margin-bottom: 33px;
                height: 50px;
                font-weight: bold;
            }
        }
    }
}
