@use "src/styles/pallete";
@use "input-money-container" as input;

@use "add-contribution-wrapper";
@use "dialog-modal";
@use "pop-up";


#direct-debit-wrapper {


	h2 {
		text-align: center;
	}

	.didnt-find-your-bank {
		text-align: center;
	}

	.ddi-pdf-download-header {
		color: rgb(0,0,0);
		padding-bottom: 30px;
	}

	.intermediary-container {
		width: 100%;
	}

	.selected {
		background: rgba(pallete.$green2, 0.6);
	}

	.bank-card-list {
		width: 100%;
		min-width: 150px;
		min-height: 150px;
		max-height: 250px;
		cursor: pointer;
		background: white;
		border: 1px solid #cccccc !important;

		* {
			box-shadow: none;
		}

		img {
			max-height: 200px;
		}
	}

	.tab-class-holder {
		width: 100%;
		background: pallete.$information;

		.tab-container {
			.MuiAppBar-colorPrimary {
				background: transparent;
				box-shadow: none;

				span {
					font-weight: bold;
				}
			}

			text-align: center;
			max-width: 768px;

			button {
				width: 100%;
			}
		}
	}

	@include input.input-money-container-styles;


	#digit-your-bank-container {
		h2 {
			color: black;
			text-align: center;
		}

		border: 1px solid rgba(pallete.$black_2, 0.5);

		.info-container {
			color: pallete.$black;
		}

		#fetching-data {
			padding-top: 20px;
			padding-bottom: 20px;

			button {
				width: 20%;
				margin: 0 auto;
			}
		}

		#digit-your-bank {
			padding-right: 20px;
			padding-left: 20px;
			padding-top: 25px;
			min-height: 91vh;
			height: auto;

			p {
				font-size: 13px;
			}

			.MuiFormControl-fullWidth {
				margin-bottom: 20px;
			}
		}

		#direct-debit-disclaimer, #digit-your-bank {
			width: 100%;
		}

		#continue-button-container {
			padding-top: 20px;
			width: 100%;
			margin-bottom: 20px;

			button {
				width: 100%;
				background: pallete.$information;
				color: white;
				font-weight: bold;
				height: 50px;
			}
		}
	}

	.bank-list {
		padding: 20px;

		.bank-logo {
			img {
				width: 100%;
			}
		}

		.bank-card-list {
			border: none;
			box-shadow: none;
			border-radius: 10px;
		}
	}

}

#connection-details-container {
	@extend #your-pension-wrapper !optional;

	#your-collegia-account-header {
		background: pallete.$backgroundColor4;

		p {
			color: black;

		}

		svg {
			color: pallete.$information;
		}

		p {
			font-size: 20.1px;
			line-height: 2.4;
			padding-right: 65px;
		}
	}

	#info-container {
		padding: 25px 15px;

		* {
			color: pallete.$black;
			font-size: 14px;
		}

	}

}

#header-your-retirement {
	background: pallete.$backgroundColor4;
	color: black;
	min-height: 50px;
	height: auto;
	.MuiIconButton-root:hover {
		background: transparent;
	}

	svg {
		color: pallete.$information;
	}

	p {
		font-size: 20.1px;
		line-height: 2.4;
		padding-right: 65px;
		position: relative;
		left: 5%;
		right: 0;
	}

	.header-custom-icon {
		.default-img-size {
			width: 35%;
		}

		.full-img-size {
			width: 55%;
		}

		padding-top: 14px;
		padding-left: 10px;
	}
}
