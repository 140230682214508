#profile-header-page-container {
    max-width: 1200px;
    width: auto;
    align-content: flex-start;
    margin-left: 3%;
    .profile-picture {
        border-radius: 100px;
        height: 65px;
        width: 65px;
    }

    min-height: 4rem;

    #inner {
        max-width: 329px;
        max-height: 160px;
        #avatar-container {
            height: 100px;
            width: 100px;
            .profile-picture {
                width: 100%;
                height: 100%;
            }
        }
        svg {
            color: #C5CEE0;
            width: 100px;
            height: 100px;
        }
    }

    .left-padded {
        padding-left: 50px;
    }

    .this-is-your-retirement-story {
        font-size: 14px;
    }
    .hello-individual {
        font-style: normal;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
    }

}

#profile-header-page-container-my-users {
    max-width: 1200px;
    .profile-picture {
        border-radius: 100px;
        height: 65px;
        width: 65px;
    }

    background: white;
    min-height: 14rem;

    #inner {
        max-width: 329px;
        max-height: 160px;
        #avatar-container {
            height: 100px;
            width: 100px;
            .profile-picture {
                width: 100%;
                height: 100%;
            }
        }
        svg {
            color: #C5CEE0;
            width: 100px;
            height: 100px;
        }
    }

    .left-padded {
        padding-left: 50px;
    }

    .this-is-your-retirement-story {
        font-size: 14px;

        //padding: 0 30px;
    }
    .hello-individual {
        font-style: normal;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
    }

}
