@use "src/styles/pallete";

.secondary-header {
	padding: 1rem 0;
	margin-top: -2px;
	margin-bottom: -2px;
	color: pallete.$information;
	font-weight: bold;
	font-size: 0.95rem;
	letter-spacing: 0.02em;
	background-color: white;
}

.primary-header {
	background: pallete.$information;
	color: white;
	padding: 25px 0;
	font-size: 1.4rem;
	line-height: 22px;
	letter-spacing: 0.02em;
	min-height: 74px;
}

.manage-your-companies-header {
	@extend .primary-header;
	border-radius: 8px 8px 0 0;
	cursor: pointer;
	background: linear-gradient(269.93deg, #4062B8 0.05%, rgba(64, 98, 184, 0) 99.94%), #1F3762;
	transition: 500ms;

	&:hover {
		background: linear-gradient(269.93deg, #4062B8 0.05%, rgba(64, 98, 184, 0) 99.94%), pallete.$information;
		transition: 500ms;
	}
}
