@use "src/styles/pallete" as pallete;

@mixin input-money-container-styles {
	// Estilos gerais para o container
	color: black;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 25px;
	background: white;
	height: auto;

	#gov-top-up-container {
		margin-top: 10px;
		min-height: 56px;

		* {
			color: rgba(pallete.$black, 0.7);
		}

		button {
			background-color: pallete.$backgroundColor5;
			box-shadow: none;
			border-radius: 10px;
		}

		.gov-top-up-value {
			color: pallete.$information;
			font-weight: bold;
		}
	}

	#money-input-text-field {
		width: 100%;

		input,
		.MuiFormControl-root {
			width: 100%;
			color: pallete.$black_2;
		}

		input {
			border-color: rgba(pallete.$black, 0.2);
			font-weight: 600;
		}

		.Mui-Focused {
			border-color: rgba(pallete.$black, 0.2);
		}
	}

	#continue-button-container {
		padding-top: 20px;
		width: 100%;
		margin-bottom: 200px;

		button {
			width: 100%;
			background: pallete.$information;
			color: white;
			font-weight: bold;
			height: 50px;
		}
	}
}
