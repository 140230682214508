@use "src/styles/pallete";

@mixin add-contribution-select-container {
    color: black;
    padding-top: 29px;

    p {
        font-size: 13px;
    }
    padding-left: 20px;
    padding-right: 20px;

    .select-range-holder {
        select {
            width: 100%;
            color: pallete.$black_2;
            font-weight: 600;

            /* styling */
            background-color: white;
            border-radius: 4px;
            display: inline-block;
            line-height: 1.5em;
            padding: 0.5em 3.5em 0.5em 1em;

            /* reset */

            margin: 0;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-appearance: none;
            -moz-appearance: none;

            background-image:
                    linear-gradient(45deg, transparent 50%, black 50%),
                    linear-gradient(135deg, black 50%, transparent 50%);
            background-position:
                    calc(100% - 20px) calc(1em + 2px),
                    calc(100% - 15px) calc(1em + 2px),
                    100% 0;
            background-size:
                    5px 5px,
                    5px 5px,
                    2.5em 2.5em;
            background-repeat: no-repeat;
        }
    }

    #continue-button-container {
        padding-top: 20px;
        width: 100%;
        margin-bottom: 200px;

        button {
            width: 100%;
            background: pallete.$information;
            color: white;
            font-weight: bold;
            height: 50px;
        }

        .disclaimer {
            margin-top: 25px;
        }
    }

    .security-card {
        background: pallete.$backgroundColor4;
        min-width: 374px;
        min-height: 89px;
        padding: 21px 16px 20px 21px;
        margin-bottom: 20px;
        border-radius: 10px;
    }

    .first-contribution-date {
        input, svg {
            color: rgba(pallete.$information, 0.5);
            font-weight: bold;
            border-color: black;
        }

        input {
            &:focus {
                outline: none;
            }
        }

        margin-bottom: 40px;
    }
}


#add-contribution-select-container {
	@include add-contribution-select-container();
}
