@use "src/styles/pallete";

#signup-wrapper {

	background: white;

	#header {
		min-height: 56px;
		background-color: pallete.$backgroundColor2;
		padding-top: 12px;
		padding-bottom: 12px;
	}

	#signup-container {
		@extend #your-pension-wrapper !optional;

		min-height: auto;
		background: white;

		margin-top: 50px;

		small {
			color: pallete.$black;
		}

		.signup-form-control {
			padding: 18.5px 14px;
			border-radius: 5px;

			h5 {
				color: black;
			}
			.error {
				color: pallete.$error;
				font-weight: bold;
			}
			#telephone {
				border: 1px solid rgba(pallete.$black_2, 0.5);
			}
			input {
				color: pallete.$black_2;
			}
			label {
				//padding: 18.5px 14px;
				border-radius: 5px;
			}
		}
		.signup-form-control-checkbox {
			flex-direction: row;
			padding: 18.5px 14px;
			margin-bottom: 10%;
			font-size: 14px;
			border-radius: 5px;
			span {
				color: pallete.$black_2;
			}
		}
		.signup-form-control-2 {
			padding: 10px;
		}
		.first-title {
			color: black;

			* {
				font-size: 21px;
				font-weight: 200;
			}

			strong {
				font-weight: 700 !important;
			}
		}
		.second-title {
			color: black;
			margin-bottom: 30px;
			text-transform: uppercase;
			* {
				font-size: 10px;
				font-weight: 400;
			}
		}

		.director-text {
			color: black;
			margin-bottom: 30px;
		}

		.forgot-password {
			span {
				font-size: 11px;
				cursor: pointer;
				color: pallete.$information;
				text-align: center;
				font-weight: bold;
			}

			small {
				font-size: 11px;
				cursor: pointer;
				text-align: center;
				margin-top: 50px;
			}
		}
		.MuiButton-containedPrimary {
			background: pallete.$information;
			min-height: 50px;
		}
		.MuiButton-containedPrimary.Mui-disabled {
			background: rgba(pallete.$information, 0.5);
			// min-height: 0px;
			color: white;
		}

		.header-close-button {
			//position: absolute;
			text-align: end;
		}

		#add-director {
			color: pallete.$information;
			background: white;
			border: 1px solid rgba(pallete.$black_2, 0.5);
		}

		.confirm-company-title {
			color: black;
			* {
				font-size: 1.2rem;
				font-weight: 500;
			}
		}
		.confirm-title {
			color: black;
			padding-top: 1px;
			padding-bottom: 1px;
			* {
				//font-size: 12px;
				font-weight: 500;
			}
		}
		.confirm-description {
			color: black;
			padding-top: 1px;
			* {
				//font-size: 10px;
			}
		}
		.confirm-text {
			color: black;
			padding-top: 30px;
			padding-bottom: 1px;
			* {
				//font-size: 12px;
				font-weight: 500;
			}
		}
	}

	#signup-container-detail {
		@extend #signup-container !optional;

		background: #FBFBFB;
	}


	#direct-debit-container {
		@extend #activeaccount-container !optional;
		margin-top: 3rem;

		padding: 2rem;
		background: #F0F1F3;

		#direct-debit-disclaimer{
			margin-bottom: 2rem;
		}

		.activeaccount-form-control {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	.form-footer-information {
		margin-top: 40px;
		color: black;
		text-align: center;

		.blue {
			color: pallete.$information;
			font-weight: bolder;
			border: rgba(pallete.$black_5, 0.6) 2px solid;
			width: 100%;
			min-height: 50px;
			margin-top: 10px;
			border-radius: 5px;
			p {
				padding-top: 20px;
				padding-bottom: 10px;
			}
		}

		.pointer {
			cursor: pointer;
		}
	}

	#footer {
		background: white;
	}
}

#your-pension-wrapper {
	max-width: 768px;
	color: white;
	// min-height: 100vh;
	// height: auto !important;

	.MuiIconButton-root:hover {
		background: transparent;
	}

	@media (min-width: 769px) {
		position: relative;
		left: 50%;
		transform: translate(-50%, -0%);
	}

	.remove-bottom-padding {
		padding-bottom: 0;
	}

	.still-not-there {
		height: 25px !important;
		margin-bottom: 10px;
	}
}

.update-address{
	.MuiFormControl-root {
		width: 100%;
		margin-bottom: 0.8%;
	}
}

.form-control-color {
	color: pallete.$black_5 !important;
}

.span-style {
	margin-top: 10px;
	font-size: 0.9rem;
	margin-bottom: 3%;
}

.form-control-padding {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.grid-display-block {
	display: block;
}

.grid-display-none {
	display: none;
	min-width: 681px;
	min-height: 56px;
}

.form-control-white {
	background-color: white !important;
}

.error {
	color: pallete.$error;
	font-weight: bold;
}

.bold-span {
	.MuiFormControlLabel-label {
		font-weight: bold;
	}
}
