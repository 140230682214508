@use "src/styles/pallete";
@use "mixins/table" as t;

.table-head {
	background: pallete.$tr_background;
	box-shadow: inset 0px -1px 0px #3366FF;

	th {
		color: white;
		font-weight: 600;
		line-height: 15px;
		letter-spacing: 0.05rem;
		text-transform: uppercase;
	}
}

.icon-margin-right {
	margin-right: 2.5px;
}
.icon-margin-left {
	margin-left: 2.5px;
}

.table-cell-item-title {
	color: #25213B;
	font-weight: 500;
	line-height: 17px;
}

.table-cell-item-subtitle {
	color: #6E6893;
	font-weight: normal;
	line-height: 17px;
	letter-spacing: 0.05rem;
}

.info-icon {
	color: pallete.$information;
	cursor: pointer;
	margin-right: 12px;

	&:hover {
		color: rgba(pallete.$information, 0.8);
	}
}

.table-cell-item-text-align {
	@include t.table-cell-item-text-align;
}

td > div.table-cell-item-text-align-status-1 {
	@include t.table-cell-item-text-align;
	@extend .status-1 !optional;
}

td > div.table-cell-item-text-align-status-2 {
	@extend .table-cell-item-text-align !optional;
	@extend .status-2 !optional;
}

td > div.table-cell-item-text-align-status-3 {
	@extend .table-cell-item-text-align !optional;
	@extend .status-3 !optional;
}

td > div.table-cell-item-text-align-status-4 {
	@extend .table-cell-item-text-align !optional;
	@extend .status-4 !optional;
}

.delete-icon {
	@extend .info-icon !optional;
	margin-right: 15px;
	color: pallete.$error_status;

	&:hover {
		color: rgba(pallete.$error_status, 0.8);
	}
}

tbody {
	background: white;

	tr {
		&:hover {
			background-color: rgb(250, 250, 250);
		}
	}
}
