@use "src/styles/pallete";

@mixin contribution-header-icon-container {
    margin-top: 29px;
    padding-left: 20px;
    color: black;
    font-size: 21px;

    svg {
        height: 44px;
        width: 44px;
        color: pallete.$information;
    }
    span {
        line-height: 2.5;
        padding-left: 12px;
    }
}


#contribution-header-icon-container {
	@include contribution-header-icon-container();
}
