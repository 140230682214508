@mixin confirm-new-company-container {
	width: auto;
	max-width: 1200px;
	align-content: flex-start;
	margin-left: 3%;
	background-color: white;
}

@mixin top-banner-content {
	padding: 24px;
}

@mixin confirm-new-company-form-container {
	padding: 0 200px;

	.phone-input {
		margin-top: 15px;
	}
}
