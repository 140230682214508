@use "pallete";

#initialSetUpModal {

	.expansion-panel {
		width: 100%;

		@media (min-width: 1440px) {
			padding-right: 272px;
			padding-left: 272px;
		}

		@media (min-width: 1233px) and (max-width: 1440px) {
			padding-right: 211px;
			padding-left: 211px;
		}
	}

	.btnCloseModal {
		position: absolute;
		top: 15px;
		left: 0;
	}

	.MuiDialog-paperWidthSm, .MuiDialog-container {
		width: 100%;
	}

	.MuiDialog-paperWidthSm {
		@media (max-width: 768px) {
			max-width: 100%;
			margin: 0;
		}
	}

	.MuiContainer-root {
		outline: none;
		&:focus {
			outline: none;
			border: none !important;
		}
	}

	.modal-container {
		background: white;
		margin-top: 20px;
		width: 100%;

		.wrong-nin-number-container {
			max-width: 240px;
		}


		@media (min-width: 1441px) {
			max-width: 938px;
			padding: 0 0 40px;
		}

		@media (min-width: 1333px) and (max-width: 1440px) {
			max-width: 938px;
			padding: 0 0 40px 0;
		}


		@media (min-width: 768px) and (max-width: 1332px) {
			max-width: 938px;
			padding: 0 0 40px;
		}

		@media (min-width: 0px) and (max-width: 767px) {
			padding: 0 10px 0 10px;
		}
	}

	#modal-header {
		background: pallete.$black_4;
		align-items: center;
		.title-modal {
			text-align: left;
		}
	}

	.modal-logo {
		img {
			margin-top: 35px;
			margin-bottom: 25px;
			padding-right: 95px;
			padding-left: 95px;
		}
	}

	.subtitle-welcome {
		margin: 0 auto 25px;
		text-align: center;

		font-size: 24.1px;
	}

	.step-one-sub-header {
		h6 {
			font-size: 11.7px;
		}
	}

	.send-again-label {
		margin-bottom: 35px;
	}

	#frmInputNIN {
		text-align: center;

		#confirm_national_insurance_number {
			&:focus {
				padding-top: 14px;
			}
		}

		.MuiInputBase-adornedEnd {
			border-radius: 5px;
		}
		.primary {
			background-color: pallete.$primary_button;
			width: 100%;
			margin-top: 30px;
			color: white;
			margin-bottom: 60px;
		}
	}

	.signin {
		cursor: pointer;
		text-decoration: none;
		a {
			color:  pallete.$dark_blue;
			font-weight: 600;
			&:focus, &:hover {
				text-decoration: none;
			}
		}
	}

	.steps {
		position: initial;
		padding: 0;
		.MuiMobileStepper-progress {
			width: 100%;
		}
	}

	.step-2-container {

		text-align: center;

		padding-top: 90px;

		h4 {
			text-transform: uppercase;
			font-size: 11.7px;
		}

		h6 {
			font-size: 14px;
		}

		#frmStepTwo {
			margin-top: 20px;

			& label.Mui-focused {
				font-weight: 700;
				color: pallete.$information;
			}

			& .MuiInput-underline:after {
				border-color: pallete.$information;
			}
			& .MuiOutlinedInput-root {
				& fieldset, &:hover fieldset, &.Mui-focused fieldset {
					border: 2px solid pallete.$information;
				}
			}

			.primary {
				background-color: pallete.$primary_button;
				width: 100%;
				margin-top: 30px;
				color: white;
				margin-bottom: 55px;
			}

		}
	}
	.subtitle-welcome {
		.signin {
			color: pallete.$dark_blue;
			span {

				text-transform: none;
				font-weight: 600;
			}
		}
	}

	#stepTree {
		margin: 46px auto 0;

		@media (min-width: 425px) {
			max-width: 400px;
			padding-left: 0;
			padding-right: 0;
		}

		.create-your-login-details-label, .remember-info {
			width: 100%;
			text-align: center;
			margin-top: 10px;
			text-transform: uppercase !important;
			color: pallete.$black;
			font-size: 12px;
		}

		.create-your-login-details-label {
			font-size: 11.1px;
			margin-top: 0;
		}


		.remember-info {
			text-transform: none;
			//font-size: 14px;
		}

		#personalInfoFormContainer {
			::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder, :-moz-placeholder {
				color: pallete.$black_2;
				font-weight: 700;
			}

			margin-top: 25px;
			.MuiFormControl-root {
				width: 100%;
				.MuiGrid-root {
					width: 100%;
					margin: 0 auto;
					.MuiInputBase-root {
						width: 100%;
						input {
							width: 100%;
						}
					}
				}
			}

			.password-container {
				margin-top: 20px;
				.MuiFormHelperText-contained {
					margin-left: 0;
					color: pallete.$black;
				}
			}

			.primary {
				margin-top: 10px;
				background-color: pallete.$primary_button;
				color: white;
				text-align: center;
				width: 100%;
			}

			.signin-button {
				width: 210px;
				height: 40px;
			}

			.create-with-apple-id-button {
				margin-top: 30px;
				padding: 0;
			}

			.create-with-google-id-button {
				padding: 0;
				//margin-bottom: 77px;
			}
		}
	}


	.collegia-terms-and-conditions-link {
		text-decoration: none;
		font-weight: 700;
		color: pallete.$light_blue;
		&:hover {
			text-decoration: none;
		}
	}


	.big-picture-title-container {
		margin-top: 25px;
	}

	.icon-terms-and-conditions {
		border: none;
		background: pallete.$black_4;
		border-radius: 100px;
		padding: 3px;
		margin-right: 10px;
		path {
			color: blue !important;
		}
		color: blue !important;
	}

	.terms-and-conditions-buttons-container {
		background: white;
		padding-bottom: 45px;
		button {
			margin: 0 auto;
			text-transform: uppercase;
			width: 85%;
		}
		.primary {
			background-color: pallete.$primary_button;
			color: white;
			margin-top: 20px;
			margin-bottom: 15px;
		}
		.opt-out {
			color: pallete.$primary_button;
			background-color: white;
			margin-bottom: 17px;
		}
	}
}

#initialSetUpModalTermsAndConditions {
	@extend #initialSetUpModal;
	height: auto;
}

.generic-modal {
	padding: 15px;
	ul {
		padding: 0 0 0 15px;
		margin: 0;
		li {
			margin-bottom: 10px;
		}
	}
}

.generic-modal-body {
	border-top: 1px solid #D5D5D5;
	border-bottom: 1px solid #D5D5D5;
	margin-bottom: 15px !important;
	margin-top: 15px !important;
	padding-top: 9px !important;
	max-width: 600px;
}

.generic-modal-button {
	padding: 5px 15px;
	font-weight: 500;
	border: none !important;
	cursor: pointer;
}
