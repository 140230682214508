#forgot-password-requested-wrapper {
	.success-title {
		font-size: 1.9rem;
		color: black;
	}

	.success-subtitle {
		font-size: 0.8rem;
		font-weight: 300;
		color: black;
	}

	.success-button-margin-bottom {
		margin-bottom: 10px;
	}

	.box-shadow-button {
		box-shadow: 0 3px 1px -2px rgba(0,0,0,0.2),0 2px 2px 0px rgba(0,0,0,0.14),0 1px 5px 0px rgba(0,0,0,0.12);
		margin-top: 20px;
		margin-bottom: 10px;
	}

	.success-button-contained {
		background-color: #3f51b5 !important;
		color: white;
	}

	.success-button-contained:disabled {
		opacity: 30%;
	}
}
