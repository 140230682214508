@use "src/styles/pallete" as pallete;

@mixin account-card-styles {
	border-radius: 10px;
	width: 100%;
	background-color: white;
	margin-bottom: 10px;
	cursor: pointer;
	color: pallete.$information;

	@media (min-width: 0px) and (max-width: 475px) {
		min-width: 0;
		max-width: 354px;
	}

	.icon svg {
		color: white;
	}

	img {
		padding-top: 10px;
		padding-left: 10px;
	}

	.super-header {
		padding-left: 16px;
	}

	.MuiCardHeader-title {
		font-size: 21px;
		font-weight: bold;
	}

	.MuiCardHeader-subheader {
		font-weight: bolder;
		font-size: 11px;
	}

	.MuiCardHeader-button {
		color: white;
		background-color: pallete.$information;
		border-radius: 5px;
		border: 1px solid white;
	}

	.default {
		fill: pallete.$black_1;
		color: pallete.$black_1;

		.icon svg {
			fill: inherit;
		}
	}

	.gray {
		fill: pallete.$black_2;
		color: pallete.$black_2;

		.icon svg {
			fill: inherit;
		}
	}

	.blue {
		fill: pallete.$primary_button;
		color: pallete.$primary_button;

		.icon svg {
			fill: inherit;
		}
	}
}

@mixin inner-account-container-styles {
	& {
		color: white;
		width: 100%;
		height: 100%;
		background: pallete.$error2;

		.header-close-button {
			color: white;
		}

		#body-wrapper {
			max-width: 363px;
			color: white;
			position: relative;
			left: 50%;
			transform: translate(-50%, -0%);

			@media (min-width: 767px) {
				align-content: center;
				align-items: center;
				text-align: center;
			}

			.access-control-caption,
			.information-caption {
				font-size: 11px;
			}

			h1 {
				font-size: 23.15px;
				font-weight: 700;
				margin-top: 41px;
				margin-bottom: 8px;

				@media (min-width: 767px) {
					margin-bottom: 25px;
				}
			}

			button {
				color: white;
				font-weight: 600;
				border-color: white;
				max-width: 137px;
				max-height: 36px;
				margin-top: 72px;

				@media (min-width: 767px) {
					max-width: 254px;
					max-height: 40px;
					margin-top: 43px;
				}
			}
		}
	}
}

@mixin card-header-button-styles {
	& {
		color: white;
		background-color: pallete.$information;
		border-radius: 5px;
		border: 1px solid white;

		&:hover {
			background-color: pallete.$information;
		}
	}
}

#active-your-account-card-container,
.dashboard-card {
	@include account-card-styles;
}

#inner-active-your-account-container {
	@include inner-account-container-styles;
}

.MuiCardHeader-button {
	@include card-header-button-styles;
}
