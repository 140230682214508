@use "pallete";
@use "sass:color";
@use "scssSupport";
@use "header";
@use "modal";
@use "pages";
@use "common";
@use "queries";
@use "dialog";
@use "headers";
@use "table";
@use "statuses";
@use "tab-button";
@use "data-table";
@use "loading-panel";
@use "pop-up";
@use "layout";
@use "app-bar";
@use "opt-out";
@use "upload-bank-ddi-file";

@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,700,700i,800,800i,900,900i&display=swap");

.logo-icon-loading {
	animation: fadeLogo 5s infinite;
	animation-delay: 0.3s;
	margin: 0 auto;
	min-height: 80px;
	min-width: 80px;
	position: relative;
	top: 30vh;

	&::before {
		animation: fadeLogo 5s infinite;
		animation-delay: 0.15s;
	}

	&::after {
		animation: fadeLogo 5s infinite;
		animation-delay: 0.45s;
	}


	@keyframes fadeLogo {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
}

.yellow {
	color: pallete.$yellow;
}


.MuiButton-outlinedPrimary {
	color: pallete.$information;
	min-height: 50px;
}

.MuiButton-containedPrimary {
	background: pallete.$information;
	min-height: 50px;
}

.MuiButton-containedPrimary.Mui-disabled {
	background: color.adjust(pallete.$information, $lightness: 0.5%);
	// min-height: 0px;
	color: white;
}

.MuiButton-containedPrimary.Mui-disabled {
	color: white;
}
