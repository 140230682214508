@use "src/styles/pallete";

.status {
	padding: 1px 8px;
	border-radius: 16px;
}

// in-progress
.status-0 {
	color: pallete.$black_1;
	background: pallete.$black_4;
	//noinspection CssInvalidPropertyValue
	text-wrap: nowrap;
	@extend .status
}


.status-progress-0 {
	color: pallete.$black_4 !important;
	text-align: center;
	font-style: normal;
	font-weight: normal;
	padding: 5px;
	line-height: 14px;
	@extend .status
}

// success
.status-1 {
	color: pallete.$success_status;
	background: pallete.$success_background;
	@extend .status
}


.status-progress-1 {
	color: pallete.$success_status !important;
	text-align: center;
	font-style: normal;
	font-weight: normal;
	padding: 5px;
	line-height: 14px;
	@extend .status
}

// error
.status-2 {
	color: pallete.$error_status;
	background: pallete.$error_background;
	@extend .status
}

.status-progress-2 {
	color: pallete.$error_status !important;
	text-align: center;
	font-style: normal;
	font-weight: normal;
	padding: 5px;
	line-height: 14px;
	@extend .status
}

//pending setup
.status-3 {
	cursor: pointer;
	@extend .status-4
}

.status-progress-3 {
	color: pallete.$processing_status !important;
	text-align: center;
	font-style: normal;
	font-weight: normal;
	padding: 5px;
	line-height: 14px;
	@extend .status
}

// processing
.status-4 {
	color: pallete.$processing_status;
	background-color: pallete.$processing_background;
	@extend .status
}

