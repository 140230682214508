@use "src/styles/pallete";

.MuiCardHeader-subheader {
	font-weight: lighter !important;
	//color: white;
	font-size: 12px;
}

.MuiCircularProgress-root {
	color: pallete.$information !important;

	svg {
		fill: pallete.$information !important
	}
}

.count-employers {
	padding: 20px 0;
}

.count-employers-title {
	font-weight: bold;
	color: pallete.$dark_blue;
	line-height: 15px;
	align-items: center;
}

.count-employers-number {
	@extend .count-employers-title;
	font-weight: normal;
}

.main-buttons-container {
	padding-top: 30px;
}

#overview-table-accordion {
	background-color: #7194FF !important;
	color: white;

	.overview-table-accordion-summary {
		justify-content: center;
		min-width: 100%;
		background: linear-gradient(
				269.93deg, #4062B8 0.05%, rgba(64, 98, 184, 0) 99.94%), #1F3762;
		border-radius: 10px 10px 0 0;
	}

	.MuiAccordionSummary-content {
		justify-content: center;
	}

	.accordion-arrow-icon {
		color: white;
	}

	.MuiAccordionDetails-root {
		padding: 0;
	}

	.MUIDataTableToolbarSelect-root-265 {
		background-color: #7194FF !important;
		color: white;
	}
}

.success-button-toolbar {
	background-color: white !important;
	color: #3f51b5;
	box-shadow: 0 3px 1px -2px rgba(0,0,0,0.2),0 2px 2px 0px rgba(0,0,0,0.14),0 1px 5px 0px rgba(0,0,0,0.12);
}
