$dark_blue: #2D497A;
$dark_blue_1: #536992;
$dark_blue_2: #98A5BE;
$dark_blue_3: #C0C8D8;
$dark_blue_4: #E6E9EF;
$dark_blue_5: #1F3762;
$dark_blue_6: #2A51BC;
$dark_blue_7: #004EDE;


$light_blue: #4C85DE;
$light_blue_1: #5FA2F0;
$light_blue_2: #9AC8F7;
$light_blue_3: #C0DDFA;
$light_blue_4: #E5F2FD;
$light_blue_5: #5880ED;


$black: #272728;
$black_1: #565657;
$black_2: #7C7D7D;
$black_3: #C4C5C6;
$black_4: #E8E9EA;
$black_5: #757575;

$background_color: #F1F2F3;
$background_color2: #3366FF;
$backgroundColor: #f1f2f3;
$backgroundColor2: #f9f9f9;
$backgroundColor3: #f8f8f8;
$backgroundColor4: #f8f9fa;
$backgroundColor5: #F7F9FF;
$backgroundColor6: #979797;
$backgroundColor7: #E3E3E3;


$error: #B00020;
$error2: #FF3D71;

$warning: #B68EF1;
$success: #80C6E9;
$information: #3C69E4; // cor dos botões continue, salvar e etc... Azul padrão

$dark_blue_gradient: linear-gradient(0.25turn, #4062B8, #1f3762);
$light_blue_gradient: linear-gradient(0.25turn, #80C6E9, #3C69E4);

$min-width: 395px;
$max-width: 1212px;

$primary_button: #3C69E4;

$pink: #B68EF1;
$pink2: #FF708D;

$yellow: #FFAA00;
$yellow2: #FFC94C;


$body1: #636364;

$green: #00E096;
$green2: #18C38A;
$green3: #1CA39A;

$card2: #02163B;

$tr_background: #7194FF;

$success_status: #04825B;
$success_background: #CDFFCD;

$error_status: #FF4848;
$error_background: #FFE0E0;

$processing_status: #D5841E;
$processing_background: #FFDCB1;