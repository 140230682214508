@use "src/styles/pallete";

#employer-card-container {
	background-color: pallete.$information;

    .MuiCardHeader-button {
	    color: pallete.$information;
    }
}

#inner-employer-container {
    width: 100%;
    height: 100%;
	background: pallete.$error2;
    color: white;
    .header-close-button {
        color: white;
    }

    #body-wrapper {
        max-width: 363px;
        color: white;
        position: relative;
        left: 50%;
        transform: translate(-50%, -0%);
        @media (min-width: 767px) {
            align-content: center;
            align-items: center;
            text-align: center;
        }

        .access-control-caption, .information-caption{
            font-size: 11px;
        }

        h1 {
            font-size: 23.15px;
            font-weight: 700;
            margin-top: 41px;
            margin-bottom: 8px;
            @media (min-width: 767px) {
                margin-bottom: 25px;
            }
        }

        button {
            color: white;
            font-weight: 600;
            border-color: white;
            max-width: 137px;
            max-height: 36px;
            margin-top: 72px;
            @media (min-width: 767px) {
                max-width: 254px;
                max-height: 40px;
                margin-top: 43px;
            }
        }
    }
}
