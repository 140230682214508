@use "pallete";

#menu {
	background: white;
	color: pallete.$black_1;
	border: none;
	box-shadow: none;
	position: fixed;
	top: 0;

	.title {
		flex-grow: 1;

		img {
			vertical-align: middle;
		}
	}
}

.drawerList {
	.drawerList-button {
		&:hover {
			background-color: transparent;
		}
	}

	.MuiButtonBase-root {
		justify-content: flex-start;
		color: pallete.$black;
		font-weight: bold;
		height: 100%;

		@media(max-width: 480px) {
			justify-content: center;
		}
	}

	.right-navigation-menu-legal {
		color: pallete.$black;
		font-weight: normal;
	}
}

#menu-logged {
	max-width: 1212px;
	background: pallete.$background_color;
	color: pallete.$black_1;
	border: none;
	box-shadow: none;
	position: static;
	top: 0;
	border-radius: 20px 20px 0px 0px;
	margin-top: 60px;

	.title {
		flex-grow: 1;

		img {
			vertical-align: middle;
		}
	}
}

.advisor-header-title {
	font-weight: 500;
}

#notifications-menu {
	max-height: 400px;
	top: 2.5rem;
	.MuiMenu-paper {
		overflow-y: auto;
	}

	.notifications-menu-title {
		text-align: center;
		background-color: #3C69E4;
		color: white;
		padding: 10px;
		margin-top: -8px;
		font-weight: 600;
		font-size: large;
	}

	.notification-menu-clear-button {
		margin-top: 10px;
		padding: 10px 20px;
	}
}
