@use "confirm-company" as cc;

#confirm-new-company-container {
	@include cc.confirm-new-company-container;
}

.top-banner-content {
	@include cc.top-banner-content;
}

#confirm-new-company-form-container {
	@include cc.confirm-new-company-form-container;
}

#add-new-company-banner-title {
	background-color: white;
}
