@use "src/styles/pallete";
@use "contribution-header-icon-container" as chic;
@use "add-contribution-select-container" as acsc;

#add-contribution-wrapper {
    @extend #connection-details-container !optional;

	@include chic.contribution-header-icon-container;
	@include acsc.add-contribution-select-container;


    .continue-button {
        padding-right: 20px;
        padding-left: 21px;

        button {
            height: 50px;
            margin-top: 20px;
            color: white;
            font-weight: bold;
            background: pallete.$information;
            width: 100%;
        }
    }
}
