@use "src/styles/pallete";

#login-wrapper {

	background: white;

	#header {
		min-height: 56px;
		background-color: pallete.$backgroundColor2;
		padding-top: 12px;
		padding-bottom: 12px;
	}

	#login-container {
		@extend #your-pension-wrapper !optional;

		.error {
			color: black;
		}

		min-height: auto;
		background: white;

		margin-top: 50px;

		small {
			color: pallete.$black;
		}

		.login-form-control {
			.gray-outlined-button{
				@extend .gray-outlined-button;
			}
			padding: 10px;
			input {
				color: pallete.$black_2;
				font-weight: bold;
			}
		}
		.login-form-control-2 {
			padding: 10px;
		}
		.first-title {
			color: black;

			* {
				font-size: 21px;
				font-weight: 400;
			}
		}
		.second-title {
			color: black;
			margin-bottom: 30px;

			* {
				font-size: 15px;
				font-weight: 300;
			}
		}

		.forgot-password {
			span {
				font-size: 11px;
				cursor: pointer;
				color: pallete.$information;
				text-align: center;
				font-weight: bold;
			}
		}
		.MuiButton-containedPrimary {
			background: pallete.$information;
		}
		.Mui-disabled {
			background: rgba(pallete.$information, 0.7);
		}

		.MuiButton-containedPrimary, .Mui-disabled {
			min-height: 50px;
			color: white;
		}
	}

	.form-footer-information {
		margin-top: 40px;
		color: black;
		text-align: center;

		.blue {
			color: pallete.$information;
			font-weight: bolder;
			//width: 100%;
			min-height: 50px;
			margin-top: 10px;
			border-radius: 5px;
			p {
				border-radius: 10px;
				border: rgba(pallete.$black_5, 0.3) 2px solid;
				min-height: 50px;
				line-height: 5;
			}
		}

		.pointer {
			cursor: pointer;
		}
	}

	#footer {
		background: white;
	}
}

#your-pension-wrapper {
	max-width: 768px;
	color: white;
	// min-height: 100vh;
	// height: auto !important;

	.MuiIconButton-root:hover {
		background: transparent;
	}

	@media (min-width: 769px) {
		position: relative;
		left: 50%;
		transform: translate(-50%, -0%);
	}

	.remove-bottom-padding {
		padding-bottom: 0;
	}

	.still-not-there {
		height: 25px !important;
		margin-bottom: 10px;
	}

	#environment-buttons {
		color: black;
		margin-top: 40px;
		font-weight: bolder;

		a {
			cursor: pointer;
			text-align: center;
			width: 100%;
			padding: 16px 40px;
			border: 1px solid pallete.$information;
			border-radius: 10px;

			@media (max-width: 540px) {
				padding: 16px 10px;
				font-size: 1em;
			}

			@media (max-width: 414px) {
				padding: 16px 0;
				font-size: 0.6em;
			}

			@media (max-width: 280px) {
				padding: 16px 0;
				font-size: 0.5em;
			}
		}
		.active {
			background: pallete.$information;
			color: white;
		}
	}
}

.login-form-control-staging-date {
	.MuiInputLabel-outlined.MuiInputLabel-shrink {
		background: white;
	}
}

.staging-date-input {
	font-size: 1rem;
	padding: 18.5px 14px;
	border-radius: 5px;
	border: 1px solid lightgray;
	background-color: #FBFBFB;
}

.red-text {
	color: red !important;
}
