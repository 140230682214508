@use "src/styles/pallete";
@forward "my-account";
@forward "header";
@forward "tabs";
@forward "activeYourAccount";
@forward "businessAfterOctober2017Card";
@forward "businessBeforeOctober2017Card";
@forward "yourCollegiaAccountCard";
@forward "changeInfo";

#footer-logged {
	padding-top: 66px;
	padding-bottom: 40px;

	a {
		color: pallete.$black_2;
		font-weight: bolder;

		&:hover {
			text-transform: none;
		}
	}

	span {
		font-weight: normal;
	}

	.default-collegia-logo {
		padding-right: 12px;
	}
}

.logged-background-color {
	background: white !important;

	.logged-menu-drawer {
		max-width: 300px;
		max-height: 460px;
		right: 110px;
		border-radius: 10px;
		background-color: #F8F9FA !important;
		box-shadow: 0 0 2px 3px rgba(102, 102, 102, 0.25);
		top: 118px;

		@media (max-width: 1222px) {
			right: 0;
			min-width: 300px;
		}

		@media (min-width: 1223px) {
			right: calc((100% - 1212px) / 2);
			min-width: 300px;
		}

		@media(max-width: 480px) {
			width: 100%;
			min-width: 100%;
			right: 0;
		}

		.MuiGrid-root {
			@media (min-height: 700px) {
				max-height: 10px;
			}
		}

		.menu {
			max-width: 100% !important;
		}

		.MuiListItem-button {
			a {
				color: black;
			}
			span, a {
				font-size: 15.83px;
				@media (max-width: 480px) {
					text-align: center;
				}
			}

			&:hover {
				background-color: rgba(60, 105, 228, 0.3);
			}
		}
	}
}

.tabs-inactive {
	background-color: rgba(60, 105, 228, 0.36) !important;
	color: #FFFFFF !important;
	border-top: 2px solid #3C69E4;
	cursor: pointer;
	border-radius: 0 0 20px 0;
	&:hover {
		background-color: rgba(60, 105, 228, 0.58) !important;
	}
}

.tabs-active {
	background-color: #3C69E4 !important;
	color: #FFFFFF !important;
	border-top: 2px solid #3C69E4;
	cursor: pointer;
	border-radius: 0 0 0 20px;

}

.sidebar-footer {
bottom: 2rem;
position: relative;
}

.light-bg-container {
background: white;
padding: 15px 30px;
}

.dark-bg-container {
margin-top: 40px;
border-radius: 20px;
background: pallete.$backgroundColor4;
}

#menu-logged-wrapper {
min-height: 35px;
height: auto;
}

.main-page-container-wrapper {
//   height: auto !important;
@media (min-width: 769px) {
	position: relative;
	left: 50%;
	transform: translate(-50%, -0%);
}
}

#business-after-october-2017-card-container, #business-before-october-2017-card-container {
border-radius: 20px;
max-width: 540px;
//cursor: pointer;
border: 1px solid pallete.$backgroundColor6;
color: black;
//background-color: white;

svg {
	color: white;
}

small {
	color: pallete.$black;
	margin-left: 18.5px;
}

.Card-icon {
	margin-left: 18px;
	padding-top: 24px;
}

.Card-choose {
	margin-left: 18.5px;
	padding-top: 35px;
	padding-right: 25px;
	font-size: 11px;
}

.CardHeader-title {
	font-weight: bold;
	//margin-top: 50px;
	margin-left: 18.5px;
	color: pallete.$information;
	font-size: 21px;
	padding-right: 25px;
}

.CardHeader-subtitle {
	font-weight: bold;
	//color: white;
	font-size: 16px;
	padding-top: 26px;
	padding-right: 25px;
	margin-left: 18.5px;
}

.Card-content {
	margin-left: 18.5px;
	padding-top: 9px;
	padding-right: 25px;

	font-size: 14px;
}

.Card-form {
	h5 {
		color: black;
	}

	.error {
		color: pallete.$error;
		font-weight: bold;
	}

	padding: 18.5px 14px;
	border-radius: 5px;

	input {
		color: pallete.$black_2;
	}

	label {
		border-radius: 5px;
	}

	#stagingDate {
		border: 1px solid rgba(pallete.$black_2, 0.5);
	}
}

.MuiCardHeader-avatar {
	font-weight: bolder;
	margin-top: -45px;
	width: 10px;
	height: 24px;

	p {
		padding-top: 0;
		font-size: 18px;
	}
}

.Card-button {
	color: pallete.$information;
	background-color: white;
	border-radius: 5px;
	border: 1px solid white;
	margin-left: 18.5px;
	width: 130px;
	padding-bottom: 25px;
	padding-top: 25px;
}

.MuiCardHeader-root {
	display: flex;
	padding: 0;
	align-items: center;
}

.MuiCardHeader-icon-button {
	//padding-left:290px;
}

}

.domain-icon{
color: #F8F9FA;
background-color: #C4C4C4;
width: 40px !important;
height: 40px !important;
display: inline-block;
padding: 6px;
border-radius: 19px;
margin-left: 50%;
}

.general-information {
	font-size: 1.5rem !important;
	align-items: center;
	color: white;
}

.color-general {
	margin-top: 15px !important;
	color: white;
	font-size: 2rem !important;

}
