@use "src/styles/pallete";
@use "details-container" as dc;

@mixin direct-debit-container {
    @include dc.details-container;

    .main-title {
        padding-right: 38px;
        padding-left: 38px;
        padding-top: 35px;
        .span-red {
            color: red;
            cursor: pointer;
        }
    }

    .form-holder {
        padding-left: 21px;
        padding-right: 20px;
        margin-top: 35px;

        .react-tel-input {
            width: 100%;
            border-radius: 5px;
            color: pallete.$black_1;
            font-weight: bold;
            margin-bottom: 20px;

            #mobile {
                width: 100%;
            }
        }

        .edit-form-span {
            font-weight: bolder;
            color: pallete.$information;
            cursor: pointer;
        }

        .MuiInputBase-adornedEnd {
            border-radius: 5px;
        }
        .MuiOutlinedInput-root {
            input[type=text] {
                border-radius: 5px;
                color: pallete.$black_1;
                font-weight: bold;
            }
            margin-bottom: 20px;
        }
        .cancel-mandate-signing {
            width: 100%;
            height: 50px;
            margin-top: 30px;
            color: pallete.$error;
            text-transform: uppercase;
            &:hover {
                background: transparent;
                text-transform: uppercase;
                font-weight: bolder;
            }
        }

        .primary {
            background-color: pallete.$primary_button;

            width: 100%;
            height: 50px;
            margin-top: 30px;
            color: white;
            text-transform: uppercase;

            &:disabled {
                background: pallete.$black_2;
                font-weight: bolder;
            }
        }
        .ddg {
            margin-top: 30px;
        }

        .contact-info {
            @extend .ddg;
            a {
                color: black;
            }

            .email {
                color: pallete.$information;
            }
        }

        .ddg-img-container {
            @extend .ddg;
            margin-bottom: 90px;
        }

        .more-than-one-disclaimer {
            background: rgba(pallete.$error2, 0.2);

            color: pallete.$error;
            font-style: italic;
            font-weight: 100;

            margin-bottom: 20px;
            border-radius: 15px;

            padding: 22px 24px 18px 19px;

            a {
                color: pallete.$error;
                font-weight: bolder;
            }
        }

        .ddi-guarantee {
            font-weight: bolder;
            color: pallete.$information;
            cursor: pointer;
        }

        .checkbox {
            .Mui-checked {
                color: pallete.$black_2;
                background: pallete.$black_4;
            }
        }
    }
}

#direct-debit-container {
	@include direct-debit-container();
}
