@use "src/styles/pallete";

#my-account-container {
	.profile-picture {
		height: 65px;
		width: 65px;
		border-radius: 100px;
	}

	#paper-holder {
		width: 100%;
		box-shadow: none;
		min-height: 93vh;
		#default-container {
			padding-top: 41px;
			padding-bottom: 41px;
			.hello-individual {
				font-size: 18px;
			}
			.this-is-your-retirement-story {
				font-size: 12px;
			}

			.change-picture-icon {
				height: 24px;
				width: 24px;
				color: rgba(pallete.$information, 0.8);
				margin-bottom: 15px;
				cursor: pointer;
			}


			.has-profile-picture {
				margin-bottom: -5px;
			}
		}
	}
	@extend #your-pension-wrapper !optional;

	#your-collegia-account-header {
		background: pallete.$backgroundColor4;
		p {
			color: black;

		}
		svg {
			color: pallete.$information;
		}
		p {
			font-size: 20.1px;
			line-height: 2.4;
			padding-right: 65px;
		}
	}

	#info-container {
		padding: 25px 15px;

		* {
			color: pallete.$black;
			font-size: 14px;
		}
	}

	#inner {
		max-width: 329px;
		max-height: 160px;
		svg {
			color: #C5CEE0;
			width: 65px;
			height: 65px;
		}
	}
	.this-is-your-retirement-story {
		font-size: 14px;

		padding: 0 30px;
	}
	.hello-individual {
		font-size: 28px;
		font-weight: 700;
	}
	p {
		width: 100%;
	}

	.base-content {
		cursor: pointer;
		font-size: 15.83px;
		padding: 23px 21px 23px 16px;
		background: pallete.$backgroundColor4;
		font-weight: bold;
		margin-bottom: 5px;
		.close-account {
			color: pallete.$error2;
		}
		svg {
			color: pallete.$black_2;
		}
	}

	#profile-image-crop-container {
		.ReactCrop .ReactCrop__crop-selection {
			border-radius: 50%;
		}
	}
}

#my-account-content {
	background-color: white;

	.my-account-card-list {
		width: 100%;
		max-width: 800px;
	}

	.my-account-cards {
		padding: 10px;
		width: 100%;
		cursor: pointer;
	}

	.my-account-advisor-title {
		background-color: white;
	}
}

.my-account-change-info-cards {
	max-width: 600px;
	width: 100% !important;
	border-radius: 16px;
	padding: 16px;
}

.my-account-change-email-title {
	background-color: white !important;
	padding: 24px;
}

.my-account-change-email-buttons {
	justify-content: flex-end;
}

.my-account-change-info-grid-title {
	text-align: left;
	width: 100%;
	margin-bottom: 40px;
	.my-account-change-info-title {
		color: #3C69E4;
	}
}

#my-address {
	padding: 20px;
	margin-bottom: 20px;
	border-left: 2px solid #C6CCD7;
	border-right: 2px solid #C6CCD7;
	border-bottom: 2px solid #C6CCD7;
}
