@use "src/styles/pallete";


#employees-contributions-modal {
    display: flex;
    align-items: center;
    align-content: center;
    padding: 20px;
    overflow: auto;
    .table-container {
        background: white;
        max-height: 600px;
        svg {
            color: pallete.$error;
        }
        button {
            &:hover {
                background: transparent;
            }
        }
    }
}
