@use "src/styles/pallete";

#activeaccount-wrapper {

    background: white;

    #header {
        min-height: 56px;
        background-color: pallete.$backgroundColor2;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    #activeaccount-container {
        @extend #your-pension-wrapper !optional;

        min-height: auto;
        background: white;

        padding-top: 50px;

        small {
            color: pallete.$black;
        }

        .activeaccount-form-control {
            h5 {
                color: black;
            }
            .error {
                color: pallete.$error;
                font-weight: bold;
            }

            padding: 18.5px 14px;
            border-radius: 5px;
            input {
                color: pallete.$black_2;
            }
            label {
                //padding: 18.5px 14px;
                border-radius: 5px;
            }
        }
        .activeaccount-form-control-checkbox {
            padding: 18.5px 14px;
            border-radius: 5px;
            span {
                color: pallete.$black_2;
            }
        }
        .activeaccount-form-control-2 {
            padding: 10px;
        }
        .first-title {
            color: black;

            * {
                font-size: 28px;
                font-weight: bold;
            }
        }
        .second-title {
            color: black;
            margin-bottom: 30px;
            //text-transform: uppercase;
            * {
                font-size: 14px;
                //font-weight: 400;
            }
        }

        .director-text {
            color: black;
            margin-bottom: 30px;
        }

        .forgot-password {
            span {
                font-size: 11px;
                cursor: pointer;
                color: pallete.$information;
                text-align: center;
                font-weight: bold;
            }

            small {
                font-size: 11px;
                cursor: pointer;
                text-align: center;
                margin-top: 50px;
            }
        }
        .MuiButton-containedPrimary {
            background: pallete.$information;
        }
        .Mui-disabled {
            background: rgba(pallete.$information, 0.5);
            min-height: 0px;
            color: white;
        }

        .MuiButton-containedPrimary, .Mui-disabled {
            min-height: 50px;
            color: white;
        }

        .header-close-button {
            //position: absolute;
            text-align: end;
        }

        #add-director {
            color: pallete.$information;
            background: white;
            border: 1px solid rgba(pallete.$black_2, 0.5);
        }

        .confirm-company-title {
            color: black;
            * {
                font-size: 1.2rem;
                font-weight: 500;
            }
        }
        .confirm-title {
            color: black;
            padding-top: 1px;
            padding-bottom: 1px;
            * {
                //font-size: 12px;
                font-weight: 500;
            }
        }
        .confirm-description {
            color: black;
            padding-top: 1px;
            * {
                //font-size: 10px;
            }
        }
        .confirm-text {
            color: black;
            padding-top: 30px;
            padding-bottom: 1px;
            * {
                //font-size: 12px;
                font-weight: 500;
            }
        }
    }

    #activeaccount-container-detail {
        @extend #activeaccount-container !optional;

        background: #FBFBFB;
    }

    #direct-debit-container {
        @extend #activeaccount-container !optional;
        margin-top: 3rem;

        padding: 2rem;


        background: #F0F1F3;

        #direct-debit-disclaimer{
            margin-bottom: 2rem;
        }

        .activeaccount-form-control {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .form-footer-information {
        margin-top: 40px;
        color: black;
        text-align: center;

        .blue {
            color: pallete.$information;
            font-weight: bolder;
            border: rgba(pallete.$black_5, 0.6) 2px solid;
            width: 100%;
            min-height: 50px;
            margin-top: 10px;
            border-radius: 5px;
            p {
                padding-top: 20px;
                padding-bottom: 10px;
            }
        }

        .pointer {
            cursor: pointer;
        }
    }

    #footer {
        background: white;
    }
}
