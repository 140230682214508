.popup-code {
	padding: 1rem !important;
	background: #d5d5d5;
	margin-top: 12px !important;
	line-height: 2 !important;
	border-radius: 4px;
	margin-bottom: 12px !important;
}

.popup-text {
	line-height: 2 !important;
}

.popup-bold {
	font-weight: bold;
	line-height: 2;
}

.popup-actionWrapper {
	padding: 16px !important;
}

.popup-button {
	background: #3C69E4;
}