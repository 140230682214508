@use "src/styles/pallete";
@use "contributionsListing" as cl;
@use "details-container" as dc;
@use "direct-debit" as dd;

#direct-debit-contribution-container {
	@extend #your-pension-wrapper !optional;
	min-height: 100vh;
	background: white;
	max-width: 1268px;

	#your-collegia-account-header {
		background: pallete.$backgroundColor4;

		p {
			color: black;

		}

		svg {
			color: pallete.$information;
		}

		p {
			font-size: 20.1px;
			line-height: 2.4;
			padding-right: 65px;
		}
	}

	#info-container {
		padding: 25px 15px;

		* {
			color: pallete.$black;
			font-size: 14px;
		}

	}

	#main-information-holder {

		text-align: center;
		color: black;
		margin-top: 69px;
		margin-bottom: 10px;

		@media (min-width: 0px) and (max-width: 414px) {
			max-width: 322px;
		}

		@media (min-width: 415px) {
			max-width: 410px;
		}

		.title {
			font-size: 21px;
			margin-top: 38px;
		}

		.subtitle {
			font-size: 13px;
		}

		.MuiButton-containedPrimary {
			background: pallete.$information;
			width: 100%;
		}
	}

	.success-payment, .failed-payment {
		font-size: 11.83px;
	}

	.success-payment {
		color: pallete.$green2;
	}

	.failed-payment {
		color: pallete.$error2;
	}

	.processing-payment {
		color: pallete.$information;
	}

	@include cl.extra-contributions-default-container;
	@include cl.listing-information-holder;
	@include dc.details-container;
	@include dd.direct-debit-container;

	.default-error {
		color: pallete.$error;
		margin-top: 20px;
		font-size: 0.8em;
		cursor: pointer;

		&:hover {
			font-weight: bolder;
		}
	}
}

#direct-debit-guarantee-modal {
	div {
		outline: none;
	}

	.MuiBackdrop-root {
		background: white;
	}

	.content-container {

		ul {
			padding: 35px 33px 25px;

			li {
				padding-top: 20px;
			}
		}

		.MuiButton-outlinedPrimary {
			max-width: 95%;
			min-height: 50px;
			background: pallete.$information;
			color: white;
		}
	}

	.ddg-img-container {
		padding: 42px 134px 53.83px;
	}

}

.loading-info {
	color: black;
}
