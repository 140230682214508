.loading-panel-content {
	height: 80%;
	min-height: 60vh;
	width: 100%;
}

.circular-progress {
	min-height: 50px;
	min-width: 50px;
}
