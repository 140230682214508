@use "src/styles/pallete";

@mixin details-container {
    & {
	    width: 100%;
	    min-height: 93vh;
	    height: 100%;
	    padding-top: 24px;

	    .section-title, .section-info {
		    padding-left: 20px;
	    }

	    .section-title {
		    font-size: 12px;
		    margin-bottom: 10px;
	    }

	    .detail-info {
		    width: 100%;
		    background-color: pallete.$background_color;
		    color: pallete.$information;
		    padding-top: 20px;

		    p {
			    font-weight: bold;
			    font-size: 14px;
		    }

		    margin-bottom: 24px;
	    }
    }
}

#details-container {
	@include details-container;
}
