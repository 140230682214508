@use "src/styles/pallete";

#forgot-password-wrapper {
	@extend #login-wrapper !optional;

	#login-container {
		margin-top: 30px;
		min-height: 70vh;
	}

	form {
		padding-left: 32px;
		padding-right: 33px;

		.Mui-error {
			font-weight: bold;
		}

		small {
			color: pallete.$error2 !important;
			font-weight: bold;
			padding-left: 16px;
			padding-top: 4px;
		}
	}


	.second-title {
		font-size: 10px;
	}

	#footer {
		padding-bottom: 70px;
	}
}

#forgot-password-form {
	width: 100%;
}
