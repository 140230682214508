@use "src/styles/pallete";
@use "src/styles/scssSupport";

#initialSetUpModal {
    background: white;

    .big-picture-title-container {
        background: pallete.$backgroundColor3;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid pallete.$black_4;
    }
    .big-picture-container {
        max-width: 938px;
    }

    @media (min-width: 1025px) {
        .expansion-panel {
            padding: 0;
        }
        .big-picture-container {
            max-width: 938px;
        }
    }
}

@media (max-width: 321px), (max-width: 376px),(max-width: 426px) {
    #initialSetUpModal {
        .step-2-container {
            #frmStepTwo {
                max-width: 100%;
                label {
                    font-size: 1.1em;
                }
            }
        }
        #stepTree {
            width: 100%;
        }
        .pension-terms-header {
            padding: 0;
            h1 {
                font-size: 1.8em;
            }
        }
    }
}

.pension-terms-header {
    h1 {
        font-size: 1.8em;
    }
}

@media (max-width: 321px), (max-width: 376px),(max-width: 426px), (max-width: 769px) {
    .modal-header-title-container {
        margin: 0 auto !important;
        h5 {
            font-size: 0.8em;
        }
    }
    #root {
        overflow-x: hidden;
    }
    #footer {
        max-width: 100%;
        .want-more-container {
            margin-top: 100px;
            margin-bottom: 100px ;
            .more-about-link {
                font-weight: 700;
                color: pallete.$light_blue;
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .different-kind-of-pension-img {
        order: 1;
        float: none;
    }

    .kind-pension-title-container, .order-2-container {
        order: 2;
    }
}

@media (max-width: 768px) {
    .kind-pension-title-container, .collegia-is-yours-info-container {
        padding-left: 134px;
        padding-right: 134px;
        padding-bottom: 89px;
    }
}

#container-ready {
    width: 100% !important;
    height: 290px;
    background: pallete.$backgroundColor2;
    h4 {
        margin-bottom: 24.1px;
    }
    .button-container {
        button {
            background: pallete.$primary_button;
            height: 50px;
            width: 100%;
            max-width: 320px;
            max-height: 50px;
        }
    }
}



@media (max-width: 321px) {

    #why-collegia-container {
        margin-top: 42px;
        padding-right: 26px;
        padding-left: 29px;
        .kind-pension-title {
            margin: 30px auto 16px;
            padding: 0;
            text-align: center;
            font-size: 23px;
        }
        .paragraph {
            padding: 0;
            text-align: center;
            font-size: 14px;
        }
    }
}

#footer {
    background: white;
    color: black;
    padding-bottom: 100px;

    @media #{scssSupport.$mq-xlarge} {
        margin-top: 30px;
    }

    @media (max-width: 425px) {
        width: 425px;
    }

    @media (max-width: 414px) {
        width: 414px;
        margin-left: 0;
    }

    @media (max-width: 375px) {
        width: 375px;
    }

    @media (max-width: 360px) {
        margin-left: 0;
    }

    @media (max-width: 320px) {
        width: 320px;
    }

}
