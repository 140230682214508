@use "src/styles/pallete";

.app-bar {
	transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.app-bar-shift {
	width: calc(100% - 240px);
	transition: margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,width 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
	margin-right: 240px;
}

.app-bar-drawer {
	width: 100%;
	flex-shrink: 0;
	.typography-icon {
		margin-right: 75%;
		margin-top: 10px;
	}
}

.app-bar-drawer-header {
	display: flex;
	padding: 0px 8px;
	min-height: 56px;
	align-items: center;
	align-content: center;
	flex-direction: row;
	justify-content: flex-end;
	background-color: #F1F2F3;
}

@media (min-width:0px) and (orientation: landscape) {
	.app-bar-drawer-header {
		min-height: 48px;
	}
}

@media (min-width:600px) {
	.app-bar-drawer-header {
		min-height: 64px;
		background-color: pallete.$backgroundColor4;
	}
}

.app-bar-content {
	padding: 24px;
	flex-grow: 1;
	transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
	margin-right: -240px;
}

.app-bar-content-shift {
	transition: margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
	margin-right: 0;
}
