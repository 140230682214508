@use "src/styles/pallete";
@use "employees-contributions-modal";

.dialog-without-padding {
	padding: 0 !important;
}

.dialog-without-border-top {
	border-top: 0 !important;
}

#authorization-modal {
	#content {
		outline: none;
		background: white;
		max-width: 80%;
		position: relative;
		left: 10%;
		top: 20%;
		border-radius: 10px;

		#header {
			text-align: center;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			background: pallete.$black_4;
			padding-top: 15px;
			padding-bottom: 10px;
		}

		#body {
			padding-left: 25px;
			padding-bottom: 10px;

			.title {
				font-size: 24px;
			}

			.subtitle {
				font-size: 16px;
			}
		}

		#button-container {
			padding-bottom: 20px;
			padding-right: 20px;

			button {
				padding: 10px;
				margin: 5px;
				max-width: 110px;
				min-width: 110px;
				max-height: 50px;
			}

			.authorise {
				background: pallete.$information;
				color: white;
				font-weight: bold;
			}

			.deny {
				color: pallete.$information;
			}
		}
	}
}

.MuiAlert-filledInfo {
	a {
		font-weight: bold;
		color: white;
	}
}

.snackbar-alert-box {
	margin-top: -12px;
}
