@use "src/styles/pallete";

#your-collegia-account-card-container {
    border-radius: 10px;
    width: 100%;
    background-color: pallete.$information;
    margin-bottom: 10px;
    cursor: pointer;
    color: white;

    @media (min-width: 0px) and (max-width: 475px) {
        min-width: 0;
        max-width: 354px;
    }
    svg {
        color: white;
    }
    .MuiCardHeader-title {
        font-size: 11px;
        font-weight: normal;
    }
    .MuiCardHeader-subheader {
        font-weight: bolder;
        color: white;
        font-size: 24.1px;
    }
}

#your-collegia-account-card-container-disable {
    @extend #your-collegia-account-card-container !optional;
    cursor: default;
}
