@use "src/styles/pallete";

#select-service-wrapper-no-logged {

    background: white;

    #header {
        min-height: 56px;
        background-color: pallete.$backgroundColor2;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    #select-service-container-no-logged {
        min-height: auto;
        background: white;

        margin-top: 50px;

        small {
            color: pallete.$black;
        }

        @media (max-width: 767px) {
            padding-left: 10px;
            padding-right: 10px;
        }

        .selectservice-form-control {
            padding: 18.5px 14px;
            border-radius: 5px;
            input {
                color: pallete.$black_2;
            }
        }
        .selectservice-form-control-2 {
            padding: 10px;
        }
        .first-title {
            color: black;

            * {
                font-size: 24.1px;
                font-weight: 500;
            }
        }
        .second-title {
            color: black;
            margin-bottom: 30px;
            text-transform: uppercase;
            * {
                font-size: 10px;
                font-weight: 400;
            }
        }
        .MuiButton-containedPrimary {
            background: pallete.$information;
        }
        .Mui-disabled {
            background: rgba(pallete.$information, 0.7);
        }

        .MuiButton-containedPrimary, .Mui-disabled {
            min-height: 50px;
            color: white;
        }
    }
    .about {
        color: black;
        margin-top: 63px;

        * {
            font-size: 15px;
            font-weight: 400;
        }
    }
    .more-details {
        span {
            text-transform: none;
            font-size: 15px !important;
            cursor: pointer;
            color: pallete.$information;
            text-align: center;
            font-weight: bold;
            margin-bottom: 100px;
        }
    }

    .form-footer-information {
        margin-top: 40px;
        color: black;
        text-align: center;

        .blue {
            color: pallete.$information;
            font-weight: bolder;
            border: rgba(pallete.$black_5, 0.6) 2px solid;
            width: 100%;
            min-height: 50px;
            margin-top: 10px;
            border-radius: 5px;
            p {
                padding-top: 20px;
                padding-bottom: 10px;
            }
        }

        .pointer {
            cursor: pointer;
        }
    }

    #footer {
        background: white;
    }
}
