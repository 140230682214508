@use "src/styles/pallete";

#terms-and-conditions-container {
	@extend #my-account-container !optional;
	@extend #initialSetUpModalTermsAndConditions !optional;

	.pension-terms-header {
		color: black;

		h3 {
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}

	.big-picture-title-container {
		text-align: center;
		color: black;
		font-size: 14px;
		text-transform: uppercase;

		* {
			font-weight: bolder;
		}
	}

	.MuiAccordionSummary-root {
		background: pallete.$backgroundColor3;

		* {
			font-weight: 600;
		}
	}
}

#initialSetUpModalTermsAndConditions {
	.terms-and-conditions-header {
		margin-top: 20px;
	}
}
