.generic-modal-paper {
	position: absolute;
	min-width: 300px;
	max-width: 600px;
	border-radius: 5px;
	padding: 15px;
	background-color: white;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.generic-modal-title {
	color: #3C69E4;
	margin: 0;
}