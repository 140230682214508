@use "employercard";
@use "advisorscard";
@use "src/styles/pallete";

#select-service-wrapper {

	@media (min-width: 960px) {
		max-width: 900px;
		max-height: 470px;
		position: relative;
		left: 50%;
		transform: translate(-50%, -0%);
	}

	#employer-card-container, #advisors-card-container {
		border-radius: 20px;
		max-width: 421px;
		max-height: 470px;
		cursor: pointer;
		color: white;

		svg {
			color: white;
		}

		.MuiCardHeader-title {
			font-weight: normal;
			margin-top: 50px;
			margin-left: 20px;
			text-transform: uppercase;
		}

		.MuiCardHeader-avatar {
			font-weight: bolder;
			margin-top: -45px;
			width: 10px;
			height: 24px;

			p {
				padding-top: 0;
				font-size: 18px;
			}
		}

		.MuiCardHeader-button {
			color: pallete.$information;
			background-color: white;
			border-radius: 5px;
			border: 1px solid white;
			margin-left: 40px;
			width: 130px;
		}

		.MuiCardHeader-root {
			display: flex;
			padding: 0;
			align-items: center;
		}

		.MuiCardHeader-icon-button {
			//padding-left:290px;
		}


		.MuiCardHeader-subheader {
			font-weight: bolder;
			color: white;
			font-size: 24.1px;
			margin-top: 146px;
			margin-left: 20px;
		}

		.MuiCard-content {
			margin-left: 30px;
		}

		.MuiTypography-body1 {
			font-size: 13px;
		}
	}
}

