@use "src/styles/pallete";

#root, body, html {
	background-color: white;
	display: flex;
	width: 100%;
	height: 100%;
}

* {
	font-family: 'Montserrat', sans-serif !important;
}

.hide {
	display: none;
}

.img-responsive {
	max-width: 100%;
}

a {
	text-decoration: none;
	color: pallete.$light_blue;
}

.modal {
	display: flex;
	align-items: center;
	justify-content: center;

	.default-error {
		margin-top: 10px;
	}
}

.loading {
	.MuiCircularProgress-root {
		margin: 0 auto;
		min-height: 80px;
		min-width: 80px;
		position: relative;
		top: 30vh;
		color: pallete.$information
	}
}

#absolute-loading {
	position: absolute;
	top: 50%;
	left: 50%;


	@media (min-width: 0px) and (max-width: 425px) {
		left: 43%;
	}
}

.no-padding {
	padding: 0 !important;

	@media (max-width: 321px) {
		min-width: 320px;
		max-width: 320px !important;
		margin-right: 0;
		margin-left: 0;
	}
}

.italic {
	font-style: italic;
}

.bolder {
	font-weight: bolder;
}

.bolder-force {
	font-weight: bolder !important;
}

.force-normal-font {
	font-weight: normal !important;
}

.collegia-text-danger {
	color: pallete.$error;
}

.no-border {
	border: none !important;
}

.MuiContainer-root {
	background: white;
}

.half-opacity {
	opacity: 0.50;
}

.blue {
	color: pallete.$dark_blue;
}

.intermediary-container {
	@extend #connection-details-container !optional;
	min-height: 84vh;
}

.MuiButton-root {
	min-height: 50px;
}

.MuiButton-containedPrimary {
	background: pallete.$information;
}

.MuiButton-containedPrimary.Mui-disabled {
	background: rgba(pallete.$information, 0.5) !important;
	color: white !important;
}

.MuiPaginationItem-root.Mui-selected {
	background: pallete.$information !important;
}

.container-wrapper {
	padding: 1rem;
	margin: 0 auto;
}

.collegia-border {
	border: 2px solid #C6CCD7;
}

.collegia-container-wrapper {
	max-width: 1212px;

	#direct-debit-button-container {
		span {
			width: 100%;

			button {
				border-radius: 0;
			}
		}

		button.setup-button {
			background-color: pallete.$yellow;
			color: white;
			font-weight: bold;
		}
	}
}

.no-padding-top {
	padding-top: 0 !important;
}

.drag-and-drop {
	color: pallete.$information;
	margin-top: 10px !important;
}

.MuiContainer-maxWidthLg {
	max-width: 1212px !important;
}

.default-button {
	color: white !important;
	background-color: pallete.$information !important;

	&:hover {
		background-color: rgba(pallete.$information, 0.90) !important;
	}
}

.gray-outlined-button {
	border: rgba(117, 117, 117, 0.3) 2px solid;
}

#beneficiary-information {
	border-radius: 10px;
	width: 100%;
	background-color: #FF3D71;
	margin-bottom: 10px;
	cursor: pointer;
	color: white;

	@media (min-width: 0px) and (max-width: 475px) {
		min-width: 0;
		max-width: 354px;
	}

	svg {
		color: white;
	}


	.MuiCardHeader-title {
		font-size: 11px;
		font-weight: normal;
	}

	.MuiCardHeader-subheader {
		font-weight: bolder;
		color: white;
		font-size: 24.1px;
	}

	.MuiCardHeader-button {
		color: white;
		border-radius: 5px;
		border: 1px solid white;
	}

	.MuiCardHeader-icon-button {
		//padding-left:290px;
	}
}

.card-background {
	background-color: #3c69e4 !important;
}

.margin-button {
	margin-bottom: 45px !important;
}

.margin-text {
	margin-top: 20px !important;
	margin-bottom:20px !important;
}

.mb-15 {
	margin-bottom: 15px !important;
}
