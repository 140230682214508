@use "src/styles/pallete";

#opt-modal-modal {
	display: flex;
	align-items: center !important;
	align-content: center !important;
	justify-content: center !important;
	padding: 20px !important;
	.grid-modal-opt-out {
		border-radius: 5px;
		background-color: white;
		max-width: 1000px !important;
		max-height: 500px !important;
		overflow: scroll;
	}
	.break-line {
		white-space: pre-wrap;
		overflow-wrap: break-word;
	}
	.grid-modal-scroll-opt-out {
		border-radius: 5px;
		background-color: white;
	}
	.MuiCardHeader-button {
		color: white;
		background-color: pallete.$information;
		border-radius: 5px;
		border: 1px solid white;
	}

	.Mui-disabled {
		background: rgba(pallete.$information, 0.5);
		color: white;
	}

}
