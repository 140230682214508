@use "src/styles/pallete";
@use "sass:color";

.upload-bank-ddi-file {
	width: 100%;
	padding: 2%;
	border: 3px solid #C6CCD7;

	.upload-bank-ddi-file-processing-file {
		font-size: 25px !important;
		color: #3C69E4;
		margin-bottom: 2%;
	}

	.upload-logo-no-hover {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin-top: 8px;
		min-height: 12rem;
		padding: 2rem;
		width: 100%;
		margin-right: 17px;
		margin-left: 17px;
		max-height: 30% !important;
	}

	.drag-and-drop-bank-ddi-file {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin-top: 5px;
		min-height: 25rem;
		width: 1190px;
		height: 300px;
		outline-width: 0;
		border: 1px dashed #ABABAB;

		@media (max-width: 1274px) {
			width: calc((100vw + (100vw - 45px)) / 2) !important;
		}


		&:hover {
			transition: linear 0.12s;
			background: color.adjust(pallete.$light_blue, $lightness: 30%);
		}

		svg {
			width: 6rem;
			height: 6rem;
			color: #4061b7
		}
	}

	.upload-bank-ddi-file-img-drawing {
		height: 300px;
		margin-top: 1%;
	}

	.upload-bank-ddi-deleted {
		margin-left: 1%;
		color: #F44336;
	}
}

.btn-download-ddi-file {
	max-height: 50px;
	background-color: rgb(255, 255, 255);
	color: rgb(25, 118, 210);
	cursor: pointer;
	.btn-download-ddi-file-icon {
		margin-right: 5px;
		margin-bottom: -10px;
	}
}

.company-info-buttons-refresh {
	position: relative !important;
	font-style: normal !important;
	font-weight: normal !important;
	line-height: 1.1rem !important;
	width: 200px;
}

.fill-online {
	color: rgb(25, 118, 210);
	padding-left: 15px;
	cursor: pointer;
}

.upload-ddi-history-modal {
	display: flex;
	align-items: center !important;
	align-content: center !important;
	justify-content: center !important;
	padding: 20px !important;

	.grid-modal {
		border-radius: 5px;
		padding: 10px;
		background-color: white !important;
		max-width: 600px !important;
		min-height: 300px !important;
	}
	.form-control-invite-employee {
		width: 500px !important;
		margin-bottom: 10px;
	}

	.generic-modal-style {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		position: absolute;
		min-width: 300px;
		background-color: whitesmoke;
		border-radius: 5px;
		box-shadow: 1px 2px 18px;
		padding: 15px;
	}
}
